import { switchDateFormat } from '@/helpers/format-date-service';
// import backgroundImage from 'img/chart-background.svg';
import colors from './colors';
import i18n from '@/i18n';
import sma from 'sma'; // simple moving average

const yAxesTitleStyles = {
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 700,
};

const cutAverage = (data, range) => {
    return Object.keys(data)
        .map(k => [Date.parse(k), parseFloat(data[k])])
        .slice(0, range)
        .reverse();
};

const calculateRollingAverage = (data, daysRange) => {
    // number of days we want
    // the higher the value -> the straighter the graph
    const rollingDays = 30;
    const cutted = cutAverage(data, daysRange.value + rollingDays);
    // Calculate the rolling average
    const rolling = sma(
        cutted.map(a => a[1]),
        rollingDays + 1,
        n => parseFloat(n),
    );
    return rolling.map((r, i) => [_.takeRight(cutted, rolling.length)[i][0], r]);
};

const chunkifyData = (d, daysRange) => {
    // number of days of data to pull
    const { value } = daysRange;

    const key = 'data' in d ? 'data' : 1;
    const range = _.cloneDeep(d[key])
        .reverse()
        .slice(0, value);
    const chunkSize = calculateChunkSizeForData(value);

    const data = _.chunk(range, chunkSize)
        .reduce((acc, cur) => {
            if (cur.length === chunkSize) {
                acc.push(parseFloat(_.mean(cur).toFixed(2)));
            }
            return acc;
        }, [])
        .reverse();

    return data;
};

const calculateChunkSizeForData = days => {
    let size;
    switch (days) {
        case 7:
            size = 1;
            break;
        case 30:
            size = 7;
            break;
        case 90:
            size = 6;
            break;
        case 180:
            size = 5;
            break;
        case 365:
            size = 5;
            break;
        default:
            size = 4;
            break;
    }
    return size;
};

const calculateChunkSize = days => {
    let size;
    switch (days) {
        case 7:
            size = 1;
            break;
        case 30:
            size = 7;
            break;
        case 90:
            size = 14;
            break;
        case 180:
            size = 30;
            break;
        case 365:
            size = 60;
            break;
        default:
            size = 30;
            break;
    }
    return size;
};

const chartCategories = (daysRange, size = null) => {
    const { value } = daysRange;
    const chunkSize = size ? size : calculateChunkSize(value);
    const dates = [];
    const columns = parseInt(value / chunkSize);
    let subDays = 0;

    for (let index = 0; index < columns; index++) {
        dates.push(new Date(date().setDate(date().getDate() - subDays)));
        subDays = subDays + chunkSize;
    }

    return dates.reverse();
};

const date = () => new Date();

const config = (data, view, daysRange, averageData) => {
    const key = 'title' in data[0] ? 'title' : 0;
    return {
        chart: {
            height: 300,
            // plotBackgroundImage: backgroundImage,
            spacing: 0,
            style: { fontFamily: 'Roboto' },
            type: 'column',
            events: {
                render: function() {
                    const title = this.legend.title;

                    if (this.containerWidth > 659) {
                        title.translate(-120, 24);
                    }
                },
                load: function() {
                    Array.from(
                        document.querySelectorAll(
                            '.ranking-distribution .highcharts-legend-item path.highcharts-point',
                        ),
                    ).forEach(el => {
                        el.style.display = 'none';
                    });
                    Array.from(
                        document.querySelectorAll(
                            '.ranking-distribution .highcharts-legend-item path.highcharts-graph',
                        ),
                    ).forEach(el => {
                        el.setAttribute(
                            'd',
                            'M5,5 h6 a2,2 0 0 1 2,2 v6 a3,3 0 0 1 -2,2 h-6 a2,2 0 0 1 -2,-2 v-6 a2,2 0 0 1 2,-2 z',
                        );
                        el.setAttribute('fill', el.getAttribute('stroke'));
                    });
                    if (this.containerWidth <= 659) {
                        this.series.forEach(s => s.update({ pointWidth: 27 }));
                    }
                },
                redraw: function() {
                    Array.from(
                        document.querySelectorAll(
                            '.ranking-distribution .highcharts-legend-item path.highcharts-point',
                        ),
                    ).forEach(el => {
                        el.style.display = 'none';
                    });
                    Array.from(
                        document.querySelectorAll(
                            '.ranking-distribution .highcharts-legend-item path.highcharts-graph',
                        ),
                    ).forEach(el => {
                        el.setAttribute(
                            'd',
                            'M5,5 h6 a2,2 0 0 1 2,2 v6 a3,3 0 0 1 -2,2 h-6 a2,2 0 0 1 -2,-2 v-6 a2,2 0 0 1 2,-2 z',
                        );
                        el.setAttribute('fill', el.getAttribute('stroke'));
                    });
                },
            },
        },
        colors,
        credits: {
            text: '',
            href: 'https://app.keyword.com/',
        },
        exporting: { enabled: false },
        legend: {
            useHTML: true,
            align: 'left',
            margin: 20,
            symbolRadius: 3,
            verticalAlign: 'top',
            x: 120,
            itemStyle: {
                fontFamily: 'Roboto',
                fontSize: 14,
                fontWeight: 400,
            },
            title: {
                text: i18n.t('ranking-dist'),
                style: {
                    fontFamily: 'Roboto',
                    fontWeight: 600,
                },
            },
        },
        plotOptions: {
            column: { stacking: view === 'percentage' ? 'percent' : 'normal' },
            series: { pointWidth: 40 },
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 659,
                    },
                    chartOptions: {
                        legend: {
                            x: 0,
                        },
                    },
                },
            ],
        },
        series: [
            ...data
                .map(d => {
                    return {
                        name: d[key].replace('Unranked', 'Non-ranking'),
                        data: chunkifyData(d, daysRange),
                        yAxis: 0,
                        dataLabels: {
                            enabled: true,
                            color: '#333',
                            formatter: function() {
                                return !this.y
                                    ? ''
                                    : view === 'percentage'
                                    ? `${Math.round((this.y / this.total) * 100 * 1e2) / 1e2}%`
                                    : this.y;
                            },
                            style: {
                                fontSize: '10px',
                                fontWeight: 400,
                                textOutline: false,
                            },
                        },
                    };
                    // reverse ranges order 1 -> 100 -> unranked
                })
                .reverse(),
            {
                data: cutAverage(averageData, daysRange.value),
                marker: { enabled: false, lineWidth: 1 },
                name: 'Daily Avg Rank',
                type: 'spline',
                xAxis: 1,
                yAxis: 1,
            },
            {
                data: calculateRollingAverage(_.cloneDeep(averageData), daysRange),
                marker: { enabled: false, lineWidth: 1 },
                name: '30-Day Avg Rank',
                type: 'spline',
                xAxis: 1,
                yAxis: 1,
            },
        ],
        title: { text: '' },
        xAxis: [
            {
                categories: chartCategories(daysRange),
                labels: {
                    formatter: function() {
                        return switchDateFormat(this.value, 'chart-date');
                    },
                },
            },
            {
                visible: false,
            },
        ],
        yAxis: [
            {
                tickAmount: view === 'percentage' ? 3 : null,
                max: view === 'percentage' ? 100 : null,
                opposite: true,
                title: {
                    style: yAxesTitleStyles,
                    text: i18n.t(view),
                },
                labels: {
                    format: view === 'percentage' ? '{value}%' : '{value}',
                },
            },
            {
                reversed: true,
                title: {
                    style: yAxesTitleStyles,
                    text: i18n.t('average-keyword-rank'),
                },
            },
        ],
        tooltip: {
            padding: 0,
            borderColor: '#AEBBC8',
            shadow: {
                color: 'rgba(0, 0, 0, 0.25)',
                offsetX: 0,
                offsetY: 1,
                width: 4,
            },
            shared: true,
            useHTML: true,
            formatter: function() {
                return this.points.reduce(
                    (acc, cur) => {
                        return (
                            acc +
                            `
                                ${
                                    cur.series.name.includes('Daily Avg Rank')
                                        ?   `<div class="chart-tooltip__line">
                                                <span style="color: #333; font-size: 12px; font-weight: 500;">
                                                    ${switchDateFormat(new Date(this.x), 'F jS, Y')}
                                                </span>
                                            </div>`
                                        : ''
                                }
                                <div class="chart-tooltip__line">
                                    <i style="background-color: ${
                                            cur.series.color
                                        }; height: 7px; width: 7px;" class="chart-tooltip__marker${
                                            cur.series.name.includes('Avg Rank') ? ' chart-tooltip__marker_flat' : ''
                                        }"></i>
                                    <div style="flex: 1 1 auto;">${cur.series.name}</div>
                                    <div>${
                                        view === 'percentage' && !cur.series.name.includes('Avg Rank')
                                            ? `${_.round(cur.percentage, 2)}%`
                                            : _.round(cur.y, 2)
                                    }</div>
                                </div>
                            `
                        );
                    },
                    this.points[0].series.name.includes('Avg Rank')
                        ? ''
                        : `
                            <span style="color: #333; font-size: 12px; font-weight: 500;">
                            ${switchDateFormat(new Date(this.x).setDate(this.x.getDate() - 8), 'F jS, Y')} 
                                - ${switchDateFormat(new Date(this.x).setDate(this.x.getDate() - 1), 'F jS, Y')}
                            </span>
                        `,
                );
            },
        },
    };
};

export { config };
